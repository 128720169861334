import { Component, Input, OnInit } from '@angular/core';
import { navItems } from './../../_nav';

import { Router } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';
import { UserService } from '../../services/user/user.service';
import { HttpService } from '../../services/http/http.service';
import { LoggerService } from '../../services/logger/logger.service';
import { LocalService } from '../../services/local/local.service';
import { take } from 'rxjs/operators';


import * as firebase from 'firebase/app'
import { ToastrService } from 'ngx-toastr';




@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss']
})
export class DefaultLayoutComponent implements OnInit {
  public currentUser;
  private tempNavItem = navItems;
  public navConfig = {
    iwo:false,
    sales:false,
    users:false,
    warehouse:false
  }
  public navName = 'iwo';
  public navItems;
  public navItems2;
  public navItems3;
  public navItems4;
  public sidebarMinimized = true;
  private changes: MutationObserver;
  public element: HTMLElement = document.body;
  public overlay = `<div id="loading-overlay" style="position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(51,51,51,0.7);
  z-index: 10000;"></div>`
  public version = "1.7.4"

  constructor(
    private authService: AuthService,
    private router: Router,
    private userService: UserService,
    private toastrService: ToastrService,
    private httpService: HttpService,
    private loggerService: LoggerService,
    public localService: LocalService
  ) {
    //$('body').css('cursor', 'wait')

    this.httpService.hello()
    this.changes = new MutationObserver((mutations) => {
      this.sidebarMinimized = document.body.classList.contains('sidebar-minimized');
    });

    this.changes.observe(<Element>this.element, {
      attributes: true
    });
  }

  logout() {
    this.authService.logout();
  }

  ngOnInit() {
    let today = this.generateTodayDate()
    this.loggerService.featureAccessCounting('freshEnter', 'onload')
    setTimeout(() => {
      this.toastrService.info('Initial loading will take some time.', 'Checking User Access')
    }, 0);
    console.log("Logged in")
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        $('body').css('cursor', 'wait')
        $('body').append(this.overlay)
        this.userService.getVersion().once('value', verSnap => {
          // setTimeout(() => {
          //   this.toastrService.info("Your Device Current Version is " + this.version, "Checking System Version")
          // }, 0);
          console.log("code version: " + verSnap.val())
          console.log("local version: " + this.version)
          if (verSnap.val() !== this.version) {
            this.userService.getHardRefreshDate().once('value', dtRefreshSnap => {
              if (!dtRefreshSnap.val() || dtRefreshSnap.val() !== today) {
                setTimeout(() => {
                  this.toastrService.info("Hard Refreshing your page in 3 seconds", "Outdated version")
                }, 0);
                this.userService.setHardRefreshDate()
                this.router.navigate(["dashboard"])
                setTimeout(() => {
                  window.location.reload()
                }, 3000);

              }
              else if (dtRefreshSnap.val() == today) {
                setTimeout(() => {
                  this.toastrService.error("This seems like is technical side issue and not yours. Please contact technical support.", "Un-match version", { timeOut: 20000 })
                }, 0);
                this.checkUserRole();
              }
              else {
                this.checkUserRole();
              }
            }, dtRefreshError => {
              setTimeout(() => {
                this.toastrService.error(dtRefreshError, "Unable to verify refresh time")
              }, 0);
              this.checkUserRole();
            })

          }
          else {
            this.checkUserRole();
          }


        }, getVersionError => {
          setTimeout(() => {
            this.toastrService.error(getVersionError, "Unable to verify version")
          }, 0);
          this.checkUserRole();
        })

      }
      else {
        this.authService.logout()
      }
    })
  }

  checkUserRole() {
    console.log("checking role")
    this.userService.getCurrentUser().then(data => {
      if (data) {
        this.currentUser = data
        console.log("currentUser", this.currentUser)
        console.log("role found: ", this.currentUser.access)
        //console.log(this.currentUser)
        let newArray = [];
        let newArray2 = [];
        let newArray3 = [];
        let newArray4 = [];
        for (let i = 0; i < this.tempNavItem.length; i++) {
          Object.keys(this.currentUser.access.features.portals).map(x => {
            // console.log(x)
            this.navConfig[x]= this.currentUser.access.features.portals[x]["read"]
            Object.keys(this.currentUser.access.features.portals[x]["menu"]).forEach(menu=>{
              if (this.tempNavItem[i].id == menu && this.currentUser.access.features.portals[x]["menu"][menu].read ) {
                if (this.tempNavItem[i].panel == 'iwo') {
                  newArray.push(this.tempNavItem[i]);
                } else if (this.tempNavItem[i].panel == 'sales') {
                  newArray2.push(this.tempNavItem[i]);
                } else if (this.tempNavItem[i].panel == 'users') {
                  newArray3.push(this.tempNavItem[i]);
                }else if (this.tempNavItem[i].panel == 'warehouse') {
                  newArray4.push(this.tempNavItem[i]);
                }
              }
  
            })
          })
        }
        console.log(newArray)
        console.log(newArray2)
        console.log(newArray3)
        console.log(newArray4)
        this.navItems = newArray;
        this.navItems2 = newArray2
        this.navItems3 = newArray3
        this.navItems4 = newArray4
        let currentPath = this.router.url.split("/")
        console.log("currentPath", currentPath)
        if (currentPath[1] == 'sales-funnel') {
          this.navName = 'sales'
        } else if (currentPath[1] == 'user-management') {
          this.navName = 'users'
        }else if (currentPath[1] == 'warehouse') {
          this.navName = 'warehouse'
        }
        if (this.currentUser.status == "active") {
          // console.log(this.currentUser.uid)

          let lastLogin
          if (this.currentUser.dtLogin) {
            lastLogin = this.generateDateTime(this.currentUser.dtLogin)
          }
          else {
            lastLogin = this.generateDateTime(Date.now())
          }
          setTimeout(async () => {
            this.toastrService.success('Your last login was ' + lastLogin, 'Welcome Back!')

            if (!this.currentUser["dtLastModifyPassword"] || Date.now() - new Date(this.currentUser["dtLastModifyPassword"]).getTime() > (90 * 24 * 60 * 60 * 1000)) {
              this.toastrService.warning("Password need to be updated every 90 days.", "Password Expired", { timeOut: 10000 })
              this.router.navigate(['account-management'])
            }

            let viewedVersion = this.localService.getViewedVersion()
            console.log(viewedVersion, this.version)
            this.localService.version = this.version


            if (viewedVersion !== this.version) {
              console.log("show change log toast")
              await this.wait(5000)
              this.toastrService.info(`Click to checkout out latest version ${this.version} updates`, 'We have new Update!').onTap
                .pipe(take(1))
                .subscribe(() => this.versionToastClickHandler());
              console.log("showed change log toast")





            }
          }, 0);

          $('body').css('cursor', '')
          $("#loading-overlay").remove()

          //action after page loaded
          // this.checkCronJob()
          return this.userService.lastLogin()
        }
        else {
          $('body').css('cursor', '')
          $("#loading-overlay").remove()
          setTimeout(() => {
            this.toastrService.error('Please contact super admin to activate your account status.', 'Account Status is not active!')
          }, 0);
          return this.authService.logout()
        }

      }
      else {
        setTimeout(() => {
          this.toastrService.error('', "Can't find the user role.")
        }, 0);
        $('body').css('cursor', '')
        $("#loading-overlay").remove()
        console.log("role not found")
        this.authService.logout()
      }

    })
  }

  switchPortal(portal) {
    if (portal == 'iwo') {
      this.navName = 'iwo'
      this.router.navigate(["dashboard"])
    } else if (portal == 'sales') {
      this.navName = 'sales'
      this.router.navigate(["sales-funnel"])

    } else if (portal == 'users') {
      this.navName = 'users'
      this.router.navigate(["user-management", "manage-users"])

    }else if (portal == 'warehouse') {
      this.navName = 'warehouse'
      this.router.navigate(["warehouse"])

    } else {
      this.navName = 'iwo'
      this.router.navigate(["dashboard"])

    }

  }

  goTo(location) {
    this.router.navigate(location)
  }

  wait(ms) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(ms)
      }, ms);
    })
  }

  versionToastClickHandler() {
    this.router.navigate(["change-log"])
  }


  checkCronJob() {

    let dtexecute = this.localService.getCronExecuteDate()
    let today = this.generateTodayDate()
    // console.log(dtexecute)
    if (!dtexecute || dtexecute !== today) {
      console.log("running cron job")
      // this.httpService.getData()
    }
  }

  generateDateTime(timestamp) {
    let addZero = function (i) {
      if (i < 10) {
        i = "0" + i;
      }
      return i;
    }
    let year = new Date(timestamp).getFullYear()
    let month = new Date(timestamp).getMonth() + 1 < 10 ? '0' + (new Date(timestamp).getMonth() + 1) : new Date(timestamp).getMonth() + 1
    let day = new Date(timestamp).getDate() < 10 ? '0' + new Date(timestamp).getDate() : new Date(timestamp).getDate()
    let hour = addZero(new Date(timestamp).getHours());
    let minute = addZero(new Date(timestamp).getMinutes());
    let second = addZero(new Date(timestamp).getSeconds());

    let newDate = year.toString() + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second
    return newDate
  }

  generateTodayDate() {
    let addZero = function (i) {
      if (i < 10) {
        i = "0" + i;
      }
      return i;
    }
    let timestamp = Date.now()
    let year = new Date(timestamp).getFullYear()
    let month = new Date(timestamp).getMonth() + 1 < 10 ? '0' + (new Date(timestamp).getMonth() + 1) : new Date(timestamp).getMonth() + 1
    let day = new Date(timestamp).getDate() < 10 ? '0' + new Date(timestamp).getDate() : new Date(timestamp).getDate()
    let hour = addZero(new Date(timestamp).getHours());
    let minute = addZero(new Date(timestamp).getMinutes());
    let second = addZero(new Date(timestamp).getSeconds());

    let newDate = year.toString() + '-' + month + '-' + day
    return newDate
  }

}
